// when updating pricing version: remember to find all strings in src folder starting by the previous prefix, e.g. "7."
import pricing, {
  pricingInverse as pricingInverseAll, metadata as metadataAll,
  upcyclingPricing as upcyclingPricingAll, upcyclingPricingInverse as upcyclingPricingInverseAll,
  leatherGoodsPricing as leatherGoodsPricingAll, leatherGoodsPricingInverse as leatherGoodsPricingInverseAll,
} from 'tilli-core/dist/pricings/v13/pricings';
import pricingV12, {
  pricingInverse as pricingInverseAllV12, metadata as metadataAllV12,
  upcyclingPricing as upcyclingPricingAllV12, upcyclingPricingInverse as upcyclingPricingInverseAllV12,
  leatherGoodsPricing as leatherGoodsPricingAllV12, leatherGoodsPricingInverse as leatherGoodsPricingInverseAllV12,
} from 'tilli-core/dist/pricings/v12/pricings';
import pricingV9, {
  pricingInverse as pricingInverseAllV9, metadata as metadataAllV9,
  upcyclingPricing as upcyclingPricingAllV9, upcyclingPricingInverse as upcyclingPricingInverseAllV9,
  leatherGoodsPricing as leatherGoodsPricingAllV9, leatherGoodsPricingInverse as leatherGoodsPricingInverseAllV9,
} from 'tilli-core/dist/pricings/v9/pricings';
import { repairBonusDiscount } from 'tilli-core/dist/services/repairBonus';

export function getPricing(pricingVersion = 13) {
  if (pricingVersion === 9) return pricingV9;
  if (pricingVersion === 12) return pricingV12;
  return pricing;
}

export function getPricingInverse(pricingVersion = 13) {
  if (pricingVersion === 9) return pricingInverseAllV9;
  if (pricingVersion === 12) return pricingInverseAllV12;
  return pricingInverseAll;
}

export function getMetadata(pricingVersion = 13) {
  if (pricingVersion === 9) return metadataAllV9;
  if (pricingVersion === 12) return metadataAllV12;
  return metadataAll;
}

export function getUpcyclingPricing(pricingVersion = 13) {
  if (pricingVersion === 9) return upcyclingPricingAllV9;
  if (pricingVersion === 12) return upcyclingPricingAllV12;
  return upcyclingPricingAll;
}

export function getUpcyclingPricingInverse(pricingVersion = 13) {
  if (pricingVersion === 9) return upcyclingPricingInverseAllV9;
  if (pricingVersion === 12) return upcyclingPricingInverseAllV12;
  return upcyclingPricingInverseAll;
}

export function getLeatherGoodsPricing(pricingVersion = 13) {
  if (pricingVersion === 9) return leatherGoodsPricingAllV9;
  if (pricingVersion === 12) return leatherGoodsPricingAllV12;
  return leatherGoodsPricingAll;
}

export function getLeatherGoodsPricingInverse(pricingVersion = 13) {
  if (pricingVersion === 9) return leatherGoodsPricingInverseAllV9;
  if (pricingVersion === 12) return leatherGoodsPricingInverseAllV12;
  return leatherGoodsPricingInverseAll;
}

export const clothSlugs = ['pant', 'mant', 'robe', 'haut', 'cost', 'pull', 'comb', 'jupe', 'mari', 'mail', 'ling'];
export const interiorsSlugs = ['ride', 'mais', 'cous', 'chair', 'sofa', 'tableClothes'];
export const leatherGoodsSlugs = ['bag', 'belt', 'wallet', 'shoes'];
export const shoesTypes = ['flat_shoes', 'heeled_shoes', 'boots', 'heeled_boots', 'sneakers'];
export const allLeatherGoodsSlugs = [...leatherGoodsSlugs, ...shoesTypes];

export const minTornHoleyPrice = (selectedSizeBag) => {
  if (selectedSizeBag === 'littleBag') {
    return 50;
  } if (selectedSizeBag === 'middleBag') {
    return 85;
  } if (selectedSizeBag === 'bigBag') {
    return 135;
  }
  return 35;
};

export const maxTornHoleyPrice = (selectedSizeBag) => {
  if (selectedSizeBag === 'littleBag') {
    return 80;
  } if (selectedSizeBag === 'middleBag') {
    return 110;
  } if (selectedSizeBag === 'bigBag') {
    return 160;
  }
  return 60;
};

export const clothSlugToElbowPadsPricingIdMap = {
  pull: '13.pull-rep-div-6',
  robe: '13.robe-rep-div-7',
  mant: '13.mant-rep-div-8',
  haut: '13.haut-rep-div-6',
  comb: '13.comb-rep-div-1',
};

export const pricingInverse = pricingInverseAll;
export const metadata = metadataAll;
export const upcyclingPricing = upcyclingPricingAll;
export const upcyclingPricingInverse = upcyclingPricingInverseAll;

export { repairBonusDiscount };
