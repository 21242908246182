/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { navigate } from 'gatsby';
import { getPackageDeliveryFees } from 'tilli-core/dist/services/orderPricePackage';
import { getTreeInverse } from 'tilli-core/trees/treeHelpers';
import { getBeginHour } from 'tilli-core/services/orderFunnel/slotsFormatting';
import { discountDeliveryFeeEurope, discountDeliveryFeeFrance } from 'tilli-core/dist/services/discountDeliveryFeeConfig';
import { getIsClothesWithOnlyRemaillage } from 'tilli-core/dist/services/order';
import getCountries from '../config/countries.ts';
import { isOrderEligibleToRepairBonus } from '../utils/repair-bonus-functions.ts';

import { pushToLayer, getOrderEvents, setOrderEvents } from '../services/googleTagManager';
import { getAvailableActiveSlots } from '../services/slotsFormatting';
import routesMap from '../Routes';
import callApi from '../services/api';
import {
  getMetadata, getPricing, getPricingInverse, getUpcyclingPricing, getUpcyclingPricingInverse,
  getLeatherGoodsPricing, getLeatherGoodsPricingInverse, repairBonusDiscount,
  allLeatherGoodsSlugs,
} from '../services/pricing';
import fetchGeolocation from '../services/IPGeolocation';
import { getUtm } from '../services/utm';
import { isToRepair } from '../services/isToRepair';
import {
  getClothesWithSuitPiecesAndSofaPartsSplitted, getTreeFromCloth,
} from '../components/order/orderSummaryContainerHelpers';
import { formatClothOrPiece } from '../services/orderFormatting';
import { getDeliveryFees, isZipcodeCover } from '../services/zipcode.ts';
import { isEnglish } from '../../languages';

const defaultCountry = getCountries((str) => str).find((country) => country.id === 'FR');

const defaultState = {
  clothes: [],
  slots: {},
  addOrEditCloth() {},
  setSlots() {},
  trackTotalPaid() {},
  getOrderRouteAndEvent() { return {}; },
  country: defaultCountry,
  hasUserSelectedCountry: false,
  pricingMetadata: getMetadata(),
  pricing: getPricing(),
  pricingInverse: getPricingInverse(),
  upcyclingPricing: getUpcyclingPricing(),
  upcyclingPricingInverse: getUpcyclingPricingInverse(),
  leatherGoodsPricing: getLeatherGoodsPricing(),
  leatherGoodsPricingInverse: getLeatherGoodsPricingInverse(),
  utmTags: getUtm(typeof window !== 'undefined' && window?.location?.search),
  getMinOrderAmount() { return 0; },
  getOrderRequest() { return {}; },
  category: '',
  location: '',
  transporter: null,
  packagePath: false,
  hasCouturiers: false,
  landingPageBrand: '',
};

const OrderContext = React.createContext(defaultState);

const expiryKey = 'orderContext_expiry';

const getFromLocalStorage = (key) => {
  const expiry = moment(localStorage.getItem(expiryKey));
  if (moment().add(-2, 'day').isBefore(expiry)) {
    return JSON.parse(localStorage.getItem(`orderContext_${key}`));
  }
  localStorage.removeItem(expiryKey);
  localStorage.removeItem(`orderContext_${key}`);
  return undefined;
};

const setInLocalStorage = (key, value) => {
  localStorage.setItem(expiryKey, (new Date()).toISOString());
  localStorage.setItem(`orderContext_${key}`, JSON.stringify(value));
};

const removeFromLocalStorage = (key) => localStorage.removeItem(`orderContext_${key}`);

const getInitialState = () => ({
  orderId: null,
  editingClothIndex: null,
  clothes: [],
  slots: {},
  promoCode: {
    code: '',
  },
  discounts: [],
  funnelType: 'standard',
  country: defaultCountry,
  hasUserSelectedCountry: false,
  category: '',
  location: '',
  email: {},
  pricingMetadata: getMetadata(),
  pricing: getPricing(),
  pricingInverse: getPricingInverse(),
  upcyclingPricing: getUpcyclingPricing(),
  upcyclingPricingInverse: getUpcyclingPricingInverse(),
  leatherGoodsPricing: getLeatherGoodsPricing(),
  leatherGoodsPricingInverse: getLeatherGoodsPricingInverse(),
  utmTags: getUtm(typeof window !== 'undefined' && window?.location?.search),
  transporter: null,
  relayPoint: {
    id: '',
    name: '',
    street: '',
    zipcode: '',
    locality: '',
  },
  transporterInsurance: null,
  hasCouturiers: false,
  landingPageBrand: '',
});

class OrderProvider extends React.Component {
  constructor() {
    super();
    this.state = getInitialState();
    setOrderEvents(getFromLocalStorage('dataLayerOrderEvents'));
  }

  componentDidMount() {
    if (localStorage.getItem('landingPage') === 'Advice') {
      localStorage.removeItem('landingPage');
      localStorage.removeItem('landingPage_expiryKey');
      this.initialize();
    } else {
      const {
        orderId, promoCode, discounts, slots,
        clothes, editingClothIndex, funnelType, location, countryId, hasUserSelectedCountry, email,
      } = this.getStateFromLocalStorage();

      const now = moment();
      const newSlots = getAvailableActiveSlots(now, getBeginHour(location, moment()), slots);

      this.setOrderContext(
        orderId,
        promoCode,
        discounts,
        newSlots,
        clothes,
        editingClothIndex,
        funnelType,
        location,
        email,
        countryId,
        hasUserSelectedCountry,
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      orderId, promoCode, discounts, slots,
      clothes, editingClothIndex, funnelType,
      country, location, hasUserSelectedCountry,
      email,
    } = this.state;

    const dataLayerOrderEvents = getOrderEvents();
    setInLocalStorage('dataLayerOrderEvents', dataLayerOrderEvents);
    if (orderId !== prevState.orderId) setInLocalStorage('orderId', orderId);
    if (promoCode !== prevState.promoCode) setInLocalStorage('promoCode', promoCode);
    if (discounts !== prevState.discounts) setInLocalStorage('discounts', discounts);
    if (slots !== prevState.slots) setInLocalStorage('slots', slots);
    if (clothes !== prevState.clothes) setInLocalStorage('clothes', clothes);
    if (editingClothIndex !== prevState.editingClothIndex) setInLocalStorage('editingClothIndex', editingClothIndex);
    if (funnelType !== prevState.funnelType) setInLocalStorage('funnelType', funnelType);
    if (location !== prevState.location) setInLocalStorage('location', location);
    if (country !== prevState.country) setInLocalStorage('countryId', country?.id);
    if (hasUserSelectedCountry !== prevState.hasUserSelectedCountry) setInLocalStorage('hasUserSelectedCountry', hasUserSelectedCountry);
    if (email !== prevState.email) setInLocalStorage('email', email);
  }

  setCountry = (country) => {
    this.setState({ country, location: '' });
  };

  setHasUserSelectedCountry = (hasUserSelectedCountry) => {
    this.setState({ hasUserSelectedCountry });
  };

  // eslint-disable-next-line react/sort-comp
  fetchCountry = () => {
    const { hasUserSelectedCountry } = this.state;
    if (hasUserSelectedCountry || !isEnglish) return;
    fetchGeolocation()
      .then((response) => {
        const t = (str) => str;
        const { hasUserSelectedCountry: newHasUserSelectedCountry } = this.state;
        const country = getCountries(t).find((countryIt) => countryIt.id === response.countryCode);
        if (country && !newHasUserSelectedCountry) this.setCountry(country, false);
      })
      .catch((data, status) => {
        console.error('Request failed for IP', status);
      });
  };

  setOrderContext = (
    orderId,
    promoCode,
    discounts,
    slots,
    clothes,
    editingClothIndex,
    funnelType,
    location,
    email,
    countryId,
    hasUserSelectedCountry,
  ) => {
    const newState = {
      orderId,
      promoCode,
      discounts,
      slots,
      clothes,
      editingClothIndex,
      funnelType,
      location,
      email,
      hasUserSelectedCountry,
    };
    if (countryId) {
      newState.country = getCountries((str) => str).find((country) => country.id === countryId);
    }
    this.setState(newState);
  };

  setSlots = (slots) => {
    this.setState({ slots });
  };

  setPromoCode = (code) => {
    const { promoCode: promoCodeState } = this.state;
    let promoCode = { ...promoCodeState };
    if (typeof code === 'string') {
      promoCode.code = code;
    } else {
      promoCode = code;
    }
    this.setState({ promoCode, discounts: promoCode.code ? [promoCode] : [] });
  };

  setOrderId = (orderId) => {
    this.setState({ orderId });
  };

  setEditingClothIndex = (editingClothIndex, callback = () => {}) => {
    this.setState({ editingClothIndex }, callback);
  };

  setLocation = (location) => {
    this.setState({ location });
  };

  setCategory = (category) => {
    this.setState({ category });
  };

  setEmail = (email) => {
    this.setState({ email });
  };

  setPackagePath = (packagePath) => {
    this.setState({ packagePath });
  };

  setRelayPoint = (id, name, street, zipcode, locality) => {
    this.setState({
      relayPoint: {
        id,
        name,
        street,
        zipcode,
        locality,
      },
    });
  };

  setHasCouturiers = (hasCouturiers) => {
    this.setState({ hasCouturiers });
  };

  checkHasCouturiers = (completeFabrics, piece) => {
    const { location } = this.state;
    const fabrics = completeFabrics.join(',');
    callApi(`/public/hasCouturiersOnZipcodeAndFabrics/${location}/${fabrics}/${piece}`)
      .then((hasCouturiers) => this.setHasCouturiers(hasCouturiers));
  };

  getStateFromLocalStorage = () => {
    const {
      orderId: orderIdState,
      promoCode: promoCodeState,
      discounts: discountsState,
      slots: slotsState,
      clothes: clothesState,
      editingClothIndex: editingClothIndexState,
      funnelType: funnelTypeState,
      location: locationState,
      email: emailState,
      country: countryState,
      hasUserSelectedCountry: hasUserSelectedCountryState,
    } = this.state;

    const orderId = getFromLocalStorage('orderId') || orderIdState;
    let promoCode = getFromLocalStorage('promoCode') || promoCodeState;
    const discounts = getFromLocalStorage('discounts') || discountsState;
    const slots = getFromLocalStorage('slots') || slotsState;
    if (getFromLocalStorage('slots')) {
      Object.keys(slots).forEach((slot) => {
        slots[slot].date = moment(slots[slot].date);
      });
    }
    const clothes = getFromLocalStorage('clothes') || clothesState;
    const editingClothIndex = getFromLocalStorage('editingClothIndex') || editingClothIndexState;
    const funnelType = getFromLocalStorage('funnelType') || funnelTypeState;
    const location = getFromLocalStorage('location') || locationState;
    const email = getFromLocalStorage('email') || emailState;
    const countryId = getFromLocalStorage('countryId') || countryState?.id || defaultCountry.id;
    const hasUserSelectedCountry = getFromLocalStorage('hasUserSelectedCountry') || hasUserSelectedCountryState;

    if (promoCode?.code && promoCode?.brand) {
      promoCode = promoCodeState; // if the user comes from a LP, then remove the promoCode
    }

    return {
      orderId,
      promoCode,
      discounts,
      slots,
      clothes,
      editingClothIndex,
      funnelType,
      location,
      email,
      country: getCountries((str) => str).find((country) => country.id === countryId),
      hasUserSelectedCountry,
    };
  };

  getOrderRouteAndEvent = () => {
    const { slots: slotsState, clothes: clothesState } = this.state;
    const slots = getFromLocalStorage('slots') || slotsState;
    const clothes = getFromLocalStorage('clothes') || clothesState;
    const dataLayerOrderEvents = getFromLocalStorage('dataLayerOrderEvents');
    if (Object.keys(slots).length && clothes.length) {
      return { restart: true, dataLayerOrderEvents, orderRoute: routesMap.Step3.url };
    }
    if (clothes.length) {
      return { restart: true, dataLayerOrderEvents, orderRoute: routesMap.Step2.url };
    }
    return { restart: false, dataLayerOrderEvents: [], orderRoute: routesMap.Step1.url };
  };

  trackTotalPaid = (newTotalPaid) => {
    const { totalPaid } = this.state;
    if (newTotalPaid !== totalPaid) {
      this.setState({ totalPaid: newTotalPaid });
      pushToLayer({ order_amount: Math.round(newTotalPaid * 100) });
    }
  };

  addOrEditCloth = (newCloth) => {
    const { clothes, editingClothIndex } = this.state;
    const newClothes = typeof editingClothIndex === 'number'
      ? clothes.map((cloth, index) => index === editingClothIndex ? newCloth : cloth)
      : [...clothes, newCloth];
    this.setState({ clothes: newClothes, editingClothIndex: null, funnelType: 'standard' });
  };

  deleteCloth = (clothIndex, { suitPiece = undefined, sofaPart = undefined }) => {
    const { clothes } = this.state;
    if (suitPiece) {
      const newClothes = [];
      clothes.forEach((cloth, index) => {
        if (index !== clothIndex) {
          newClothes.push(cloth);
        } else {
          const newCloth = { ...cloth };
          newCloth.suitPieces = newCloth.suitPieces.filter((piece) => piece !== suitPiece);
          newCloth.suitProblems = newCloth.suitProblems.filter((problem) => !problem.includes(`${suitPiece}_`));
          newCloth.suitItems = newCloth.suitItems.filter(({ piece }) => piece !== suitPiece);
          delete newCloth.suitPieceLinings[suitPiece];
          delete newCloth.suitPiecesEmbroideries[suitPiece];
          delete newCloth.suitPiecesUpcycling[suitPiece];
          newClothes.push(newCloth);
        }
      });
      this.setState({ clothes: newClothes });
    } else if (sofaPart) {
      const newClothes = [];
      clothes.forEach((cloth, index) => {
        if (index !== clothIndex) {
          newClothes.push(cloth);
        } else {
          const newCloth = { ...cloth };
          newCloth.sofaParts = newCloth.sofaParts.filter((part) => part !== sofaPart);
          delete newCloth.sofaPartsSubTypes[sofaPart];
          delete newCloth.sofaPartsItems[sofaPart];
          delete newCloth.sofaPartsNumber[sofaPart];
          newClothes.push(newCloth);
        }
      });
      this.setState({ clothes: newClothes });
    } else {
      this.setState({ clothes: clothes.filter((cloth, index) => index !== clothIndex) });
    }
  };

  duplicateCloth = (clothIndex, currentOrderItem, { suitPiece = undefined, sofaPart = undefined }) => {
    const { clothes } = this.state;
    const newClothes = [...clothes];
    const clothToDuplicate = currentOrderItem || newClothes[clothIndex];
    if (suitPiece) {
      const newCloth = {};
      newCloth.suitPieces = clothToDuplicate.suitPieces.filter((piece) => piece === suitPiece);
      newCloth.suitProblems = clothToDuplicate.suitProblems.filter((problem) => problem.includes(`${suitPiece}_`));
      newCloth.suitItems = clothToDuplicate.suitItems.filter(({ piece }) => piece === suitPiece);
      newCloth.suitPieceLinings = clothToDuplicate?.suitPieceLinings?.[suitPiece]
        ? { [suitPiece]: clothToDuplicate.suitPieceLinings[suitPiece] } : {};
      newCloth.suitPiecesEmbroideries = clothToDuplicate?.suitPiecesEmbroideries?.[suitPiece]
        ? { [suitPiece]: clothToDuplicate.suitPiecesEmbroideries[suitPiece] } : {};
      newCloth.suitPiecesUpcycling = clothToDuplicate?.suitPiecesUpcycling?.[suitPiece]
        ? { [suitPiece]: clothToDuplicate.suitPiecesUpcycling[suitPiece] } : {};

      newClothes.push({ ...clothToDuplicate, ...newCloth });
    } else if (sofaPart) {
      const newCloth = {};
      newCloth.sofaParts = [sofaPart];
      newCloth.sofaPartsSubTypes = { [sofaPart]: clothToDuplicate.sofaPartsSubTypes[sofaPart] };
      newCloth.sofaPartsItems = { [sofaPart]: clothToDuplicate.sofaPartsItems[sofaPart] };
      newCloth.sofaPartsNumber = { [sofaPart]: clothToDuplicate.sofaPartsNumber[sofaPart] };

      newClothes.push({ ...clothToDuplicate, ...newCloth });
    } else {
      newClothes.push(clothToDuplicate);
    }

    this.setState({ clothes: newClothes });
  };

  updateTransporterInsurance = (options) => {
    this.setState({ transporterInsurance: options });
  };

  updateTransporter = (transporter) => {
    this.setState({ transporter });
  };

  initialize = () => {
    this.setState(getInitialState());
    Object.keys(this.state).forEach((stateName) => {
      removeFromLocalStorage(stateName);
    });
    removeFromLocalStorage('dataLayerOrderEvents');
  };

  navigateToFirstStep = () => {
    const { clothes } = this.state;
    if (clothes.length > 1) {
      navigate(routesMap.Step1Summary.url);
    } else {
      this.setEditingClothIndex(0);
      navigate(routesMap.Step1.url);
    }
  };

  checkPromoCode = (promoCode) => {
    callApi(`public/checkPromoCode/${encodeURIComponent(promoCode)}`)
      .then((res) => {
        pushToLayer({
          event: 'Order Funnel - Promo Code Added',
          promo_code: promoCode,
        });
        this.setPromoCode(res.promoCode);
      })
      .catch(() => {});
  };

  getMinOrderAmount = (clothesArg) => {
    const { clothes: clothesState, utmTags } = this.state;
    const clothes = clothesArg || clothesState;

    const minOrderAmountForBrandRepais = 12;

    return isToRepair(clothes) && utmTags?.medium === 'linkB2B2C'
      ? minOrderAmountForBrandRepais
      : 0;
  };

  getClothes(clothesProp) {
    const { clothes } = this.state;
    return clothesProp?.length > 0 ? clothesProp : clothes;
  }

  getAllFees = (orderRequest) => {
    const { location, country, transporter } = this.state;
    if (transporter === 'DEPOSIT') return { total: 0 };
    const deliveryFee = {
      packageFee: (['PACKAGE', 'PACKAGE_LEATHER_GOODS', 'MIXED'].includes(orderRequest.process)) ? getPackageDeliveryFees(orderRequest, transporter) : 0,
      atHomeFee: (!['PACKAGE', 'PACKAGE_LEATHER_GOODS'].includes(orderRequest.process) || orderRequest.process === 'MIXED') ? getDeliveryFees(location, country) : 0,
    };
    deliveryFee.total = Object.values(deliveryFee).reduce((acc, fee) => acc + (fee || 0), 0);
    return deliveryFee;
  };

  isDoubleProcess = () => {
    const { clothes, country } = this.state;
    if (country?.id !== 'FR') return false;
    const clothesPackage = clothes.filter((cloth) => cloth.selectedProcess === 'PACKAGE');
    const clothesAtHome = clothes.filter((cloth) => cloth.selectedProcess !== 'PACKAGE');
    return clothesPackage.length > 0 && clothesAtHome.length > 0;
  };

  getProcessStep1 = () => {
    const {
      location, category, packagePath, country, hasCouturiers,
    } = this.state;
    if (packagePath) return 'PACKAGE';
    if (category === 'accessories') return 'PACKAGE';
    if (country?.id !== 'FR') return 'PACKAGE';
    if (!hasCouturiers) return 'PACKAGE';
    return isZipcodeCover(location, country) ? 'AT_HOME' : 'PACKAGE';
  };

  getProcessStep2 = () => {
    const { clothes } = this.state;
    const isDoubleProcess = this.isDoubleProcess();
    if (isDoubleProcess) return 'MIXED';

    const clothesWithoutAcce = clothes.filter((cloth) => cloth.selectedCloth !== 'acce');
    const isLeatherGoodsOnly = clothesWithoutAcce.length > 0
      && clothesWithoutAcce.every((cloth) => {
        const leatherGoodsPiece = cloth.selectedAccessory || cloth.selectedShoes;
        return allLeatherGoodsSlugs.includes(leatherGoodsPiece);
      });
    if (isLeatherGoodsOnly) return 'PACKAGE_LEATHER_GOODS';

    const clothesPackage = clothes.filter((cloth) => cloth.selectedProcess === 'PACKAGE');
    if (clothesPackage.length > 0) return 'PACKAGE';

    return 'AT_HOME';
  };

  setLandingPageBrand = (landingPageBrand) => {
    this.setState({ landingPageBrand });
  };

  getOrderRequest = (clothesProp, options = {}) => {
    const {
      discounts = [], promoCode, country, location, transporter, transporterInsurance,
      pricingMetadata, upcyclingPricingInverse, leatherGoodsPricingInverse, landingPageBrand,
    } = this.state;
    const t = (str) => str;
    const process = this.getProcessStep2();
    const clothesState = this.getClothes(clothesProp);
    const clothesWithSuitPiecesAndSofaPartsSplitted = getClothesWithSuitPiecesAndSofaPartsSplitted(t, clothesState);
    const { version: pricingVersion } = pricingMetadata;
    const brand = !!(promoCode?.brand);

    const formattedClothes = clothesWithSuitPiecesAndSofaPartsSplitted
      .reduce((acc, cloth) => {
        if (!options.keepShort && cloth.selectedCategory === 'deco') {
          for (let i = 0; i < cloth.numberOfPieces; i += 1) {
            const updatedCurtainAlterationsPackage = [...cloth.curtainAlterationsPackage];
            updatedCurtainAlterationsPackage[1] = {};
            if (!isZipcodeCover(location, country) && i > 0) {
              acc.push({
                ...cloth, numberOfPieces: 1, curtainRepairs: [], curtainAlterationsPackage: updatedCurtainAlterationsPackage,
              });
            } else {
              acc.push({ ...cloth, numberOfPieces: 1 });
            }
          }
          return acc;
        }
        return [...acc, cloth];
      }, [])
      .map((cloth, index) => ({
        ...formatClothOrPiece(
          t,
          getTreeFromCloth(t, cloth, country),
          getTreeInverse(t, cloth.selectedProcess),
          getPricing(),
          getPricingInverse(),
          upcyclingPricingInverse,
          leatherGoodsPricingInverse,
          cloth,
          brand,
        ),
        index,
        isCurrent: cloth.isCurrent,
      }));

    const newDiscounts = [...discounts];
    if (
      !options.withoutRepairBonus && isOrderEligibleToRepairBonus(landingPageBrand)
    ) {
      newDiscounts.push(repairBonusDiscount);
    }
    newDiscounts.push(isEnglish ? discountDeliveryFeeEurope : discountDeliveryFeeFrance);

    const orderRequest = {
      minOrderAmount: this.getMinOrderAmount(clothesState),
      pricingVersion,
      clothes: formattedClothes,
      discounts: newDiscounts,
      transporterChosenByCustomer: transporter,
      process,
      country: country.id,
      language: isEnglish ? 'en' : 'fr',
      transporterInsurance,
    };

    const deliveryFee = options.noDeliveryFee ? 0 : this.getAllFees(orderRequest).total;

    return {
      deliveryFee,
      ...orderRequest,
    };
  };

  render() {
    const { children } = this.props;
    const {
      orderId, promoCode, discounts = [], slots,
      clothes, editingClothIndex, funnelType,
      country, utmTags, category, location, email, transporter, relayPoint,
      packagePath, hasUserSelectedCountry, landingPageBrand, hasCouturiers, transporterInsurance,
    } = this.state;
    const editingCloth = (typeof editingClothIndex === 'number')
      ? clothes[editingClothIndex]
      : undefined;
    const orderRequest = this.getOrderRequest(clothes);
    const deliveryFee = this.getAllFees(orderRequest);
    const process = this.getProcessStep1();
    const processStep2 = this.getProcessStep2();
    const isDoubleProcess = this.isDoubleProcess();
    const isOnlyRemaillage = getIsClothesWithOnlyRemaillage(orderRequest.clothes);
    const selectedLanguage = localStorage.getItem('gatsby-i18next-language') || 'fr';

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const orderContext = {
      country,
      countryId: country.id,
      orderId,
      clothes,
      slots,
      promoCode,
      discounts: isOrderEligibleToRepairBonus() ? [...discounts, repairBonusDiscount] : discounts,
      deliveryFee,
      editingCloth,
      editingClothIndex,
      funnelType,
      utmTags,
      category,
      location,
      email,
      transporter,
      transporterInsurance,
      relayPoint,
      process,
      hasUserSelectedCountry,
      isDoubleProcess,
      packagePath,
      hasCouturiers,
      processStep2,
      orderRequest,
      landingPageBrand,
      isOnlyRemaillage,
      selectedLanguage,

      // functions
      setCountry: this.setCountry,
      setHasUserSelectedCountry: this.setHasUserSelectedCountry,
      setRelayPoint: this.setRelayPoint,
      setEmail: this.setEmail,
      setLocation: this.setLocation,
      fetchCountry: this.fetchCountry,
      addOrEditCloth: this.addOrEditCloth,
      setSlots: this.setSlots,
      setPromoCode: this.setPromoCode,
      setOrderId: this.setOrderId,
      setEditingClothIndex: this.setEditingClothIndex,
      deleteCloth: this.deleteCloth,
      duplicateCloth: this.duplicateCloth,
      updateTransporter: this.updateTransporter,
      updateTransporterInsurance: this.updateTransporterInsurance,
      trackTotalPaid: this.trackTotalPaid,
      initialize: this.initialize,
      getOrderRouteAndEvent: this.getOrderRouteAndEvent,
      navigateToFirstStep: this.navigateToFirstStep,
      checkPromoCode: this.checkPromoCode,
      getMinOrderAmount: this.getMinOrderAmount,
      getOrderRequest: this.getOrderRequest,
      setCategory: this.setCategory,
      setPackagePath: this.setPackagePath,
      checkHasCouturiers: this.checkHasCouturiers,
      setLandingPageBrand: this.setLandingPageBrand,
      getAllFees: this.getAllFees,

      // pricings
      pricings: getPricing(),
      pricingMetadata: getMetadata(),
      pricingInverse: getPricingInverse(),
      upcyclingPricing: getUpcyclingPricing(),
      upcyclingPricingInverse: getUpcyclingPricingInverse(),
      leatherGoodsPricing: getLeatherGoodsPricing(),
      leatherGoodsPricingInverse: getLeatherGoodsPricingInverse(),
    };

    return (
      <OrderContext.Provider value={orderContext}>
        {children}
      </OrderContext.Provider>
    );
  }
}

OrderProvider.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.func,
};

OrderProvider.defaultProps = {
  t: (str) => str,
};

// export default withTranslation()(OrderContext);
export default OrderContext;

export { OrderProvider };
