function getImg(path) { return path.replace('images/laRubrique', ''); }

function getPostSeo(post) {
  const {
    path, seo, datePublished, dateModified, anecdote, template,
  } = post;

  if ([3].includes(template)) {
    return {
      ...seo,
      mainEntityOfPageId: `${path}`,
      // image,
      datePublished,
      dateModified,
      authorName: anecdote ? anecdote.tillisteName : 'Beryl',
      publisherName: 'Tilli',
      publisherLogoUrl: '/logo.png',
      photo: post.listImage && getImg(post.listImage),
      ogType: 'article',
    };
  }
  const topImage = post.topImage || post.topBlock?.image?.src || post.topBlock?.image?.desktop?.src;
  const row1Image = post.row1Image
    || (post.topBlock.row1.image && post.topBlock.row1.image.src)
    || (post.topBlock.row1.image && post.topBlock.row1.image.desktop && post.topBlock.row1.image.desktop.src);
  const row2Image = post.row2Image || post.middleBlock.rows[0].image.src || post.middleBlock.rows[1].image.src;
  const imgTop = topImage && getImg(topImage);
  const image = [];
  if (imgTop) image.push(imgTop);
  const img1 = row1Image && getImg(row1Image);
  if (img1) image.push(img1);
  const img2 = row2Image && getImg(row2Image);
  if (img2) image.push(img2);

  return {
    ...seo,
    mainEntityOfPageId: `${path}`,
    image,
    datePublished,
    dateModified,
    authorName: anecdote ? anecdote.tillisteName : 'Beryl',
    publisherName: 'Tilli',
    publisherLogoUrl: '/logo.png',
    photo: imgTop,
    ogType: 'article',
  };
}

module.exports = { getPostSeo };
