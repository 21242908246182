const React = require('react');
const { trackScreen } = require('./src/services/analytics');
require('./src/layouts/index.css');

const { OrderProvider } = require('./src/context/OrderContext');
const { PaymentSystemProvider } = require('./src/context/PaymentSystemContext');
const { CustomerProvider } = require('./src/context/CustomerContext');
const { init } = require('./src/services/googleTagManager');

init();

// eslint-disable-next-line react/prop-types
exports.wrapRootElement = ({ element }) => (
  <OrderProvider>
    <PaymentSystemProvider>
      <CustomerProvider>
        {element}
      </CustomerProvider>
    </PaymentSystemProvider>
  </OrderProvider>
);

exports.onRouteUpdate = (state) => {
  trackScreen(state.location.pathname);
};
