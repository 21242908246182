export const getUtm = (url) => {
  if (!url || !url.includes('?')) return {};
  const urlParams = new URLSearchParams(url.split('?')[1]);
  const utmTags = {};
  const entries = urlParams.entries();
  // eslint-disable-next-line no-restricted-syntax
  for (const entry of entries) {
    const value = entry[1];
    if (entry[0] === 'gclid') {
      utmTags.gclid = value;
    }
    if (entry[0].startsWith('utm_')) {
      const key = entry[0].split('utm_')[1];
      utmTags[`${key}`] = `${value}`;
    }
  }
  return utmTags;
};
