import React from 'react';
import PropTypes from 'prop-types';

import callApi, { getJwtToken } from '../services/api';

const defaultState = {
  customer: undefined,
  orders: undefined,
  selectedOrder: undefined,
};

const CustomerContext = React.createContext(defaultState);

class CustomerProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      customer: undefined,
      orders: undefined,
      selectedOrder: undefined,
    };
    this.initializeCustomerContext = this.initializeCustomerContext.bind(this);
    this.setCustomer = this.setCustomer.bind(this);
    this.setCustomerAndGetOrders = this.setCustomerAndGetOrders.bind(this);
    this.setSelectedOrder = this.setSelectedOrder.bind(this);
    this.addCustomerCard = this.addCustomerCard.bind(this);
    this.addCustomerAddress = this.addCustomerAddress.bind(this);
    this.addCustomerFeedbackToOrder = this.addCustomerFeedbackToOrder.bind(this);
    this.updateAvailabilitiesToOrder = this.updateAvailabilitiesToOrder.bind(this);
    this.editCustomerInfos = this.editCustomerInfos.bind(this);
    this.editCustomerAddress = this.editCustomerAddress.bind(this);
    this.deleteCustomerCard = this.deleteCustomerCard.bind(this);
    this.deleteCustomerPaymentMethod = this.deleteCustomerPaymentMethod.bind(this);
    this.deleteCustomerAddress = this.deleteCustomerAddress.bind(this);
    this.fetchCustomer = this.fetchCustomer.bind(this);
    this.setCustomerDiscountVouchers = this.setCustomerDiscountVouchers.bind(this);
    this.setIsShadowAccount = this.setIsShadowAccount.bind(this);
    this.setCustomerFromSessionStorage = this.setCustomerFromSessionStorage.bind(this);
    this.setCustomerPaymentMethods = this.setCustomerPaymentMethods.bind(this);
  }

  setCustomer(customer) {
    this.setState({ customer });
  }

  setIsShadowAccount() {
    const { customer } = this.state;
    this.setState({
      customer: {
        ...customer,
        isShadowAccount: false,
      },
    });
  }

  setCustomerDiscountVouchers(discountVouchers) {
    const { customer } = this.state;
    this.setState({
      customer: {
        ...customer,
        discountVouchers,
      },
    });
  }

  setCustomerAndGetOrders(customer) {
    this.setState({ customer });
    callApi('public/orders').then((res) => {
      this.setState({ orders: res.orders });
    });
  }

  /**
   * Sets the selected order and updates the orders list with the new order.
   * @param {Object} selectedOrder - The order to set as the selected order.
   */
  setSelectedOrder(selectedOrder) {
    if (typeof window !== 'undefined') window.scrollTo(0, 0);

    this.setState((prev) => {
      const previousOrders = prev?.orders ? prev?.orders : [];

      return {
        selectedOrder,
        orders: (!prev.orders || prev.orders?.length === 0)
          ? [selectedOrder] // prevent crash, not sure why it happens
          : [...previousOrders.map((order) => {
            if (order._id === selectedOrder?._id) {
              return selectedOrder;
            }
            return order;
          })],
      };
    });
  }

  setCustomerFromSessionStorage() {
    const { customer: customerState } = this.state;
    const customer = sessionStorage.getItem('customer') ? JSON.parse(sessionStorage.getItem('customer')) : customerState;
    this.setState({ customer });
  }

  setCustomerPaymentMethods() {
    const { customer } = this.state;

    if (!customer) {
      return;
    }

    const { stripeCanaryReleaseActive, paymentMethods } = customer;

    this.setState({
      customer: {
        ...customer,
        ...{ hasSomePaymentMethods: stripeCanaryReleaseActive && paymentMethods.length > 0 },
      },
    });
  }

  initializeCustomerContext() {
    this.setState({ ...defaultState });
  }

  addCustomerCard(card) {
    const { customer: customerState } = this.state;
    const customer = { ...customerState };
    customer.cards.push(card);
    this.setState({ customer: { ...customer } });
  }

  addCustomerPaymentMethod(paymentMethod) {
    const { customer: customerState } = this.state;
    const customer = { ...customerState };
    customer.paymentMethods.push(paymentMethod);
    this.setState({ customer: { ...customer } });
  }

  addCustomerAddress(address) {
    const { customer: customerState } = this.state;
    const customer = { ...customerState };
    customer.addresses.push(address);
    this.setState({ customer: { ...customer } });
  }

  addCustomerFeedbackToOrder(customerFeedbackRating) {
    const { selectedOrder: selectedOrderState } = this.state;
    const selectedOrder = { ...selectedOrderState };
    selectedOrder.customerFeedbackRating = customerFeedbackRating;
    this.setState({ selectedOrder: { ...selectedOrder } });
  }

  editCustomerInfos(infos) {
    const { customer } = this.state;
    this.setState({ customer: { ...customer, ...infos } });
  }

  editCustomerAddress(addresses) {
    const { customer: customerState } = this.state;
    const customer = { ...customerState };
    this.setState({ customer: { ...customer, addresses } });
  }

  deleteCustomerCard(id) {
    const { customer } = this.state;
    const cards = customer.cards.filter((card) => card.id !== id);
    this.setState({
      customer: {
        ...customer,
        cards: [...cards],
      },
    });
  }

  deleteCustomerPaymentMethod(id) {
    const { customer } = this.state;
    const paymentMethods = customer.paymentMethods.filter((pm) => pm.stripePaymentMethodId !== id);
    this.setState({
      customer: {
        ...customer,
        paymentMethods: [...paymentMethods],
      },
    });
  }

  deleteCustomerAddress(id) {
    const { customer } = this.state;
    const addresses = customer.addresses.filter((address) => address._id !== id);
    this.setState({
      customer: {
        ...customer,
        addresses: [...addresses],
      },
    });
  }

  fetchCustomer() {
    const jwtToken = getJwtToken();
    if (jwtToken) {
      return callApi('public/fetchMe', 'GET')
        .then(({ customer }) => {
          this.setCustomerAndGetOrders(customer);
          this.setCustomerPaymentMethods();
          return customer;
        })
        .catch(() => undefined);
    }
    return Promise.resolve();
  }

  updateAvailabilitiesToOrder(selectDateAndSlot) {
    const { selectedOrder: selectedOrderState } = this.state;
    const selectedOrder = { ...selectedOrderState };
    selectedOrder.selectDateAndSlot = selectDateAndSlot;
    this.setState({ selectedOrder: { ...selectedOrder } });
  }

  render() {
    const { children } = this.props;
    const {
      customer,
      orders,
      selectedAccountCategory,
      selectedOrder,
    } = this.state;
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const customerContext = {
      customer,
      orders,
      selectedAccountCategory,
      selectedOrder,
      setCustomer: this.setCustomer,
      setCustomerAndGetOrders: this.setCustomerAndGetOrders,
      setSelectedOrder: this.setSelectedOrder,
      addCustomerCard: this.addCustomerCard,
      addCustomerPaymentMethod: this.addCustomerPaymentMethod,
      addCustomerAddress: this.addCustomerAddress,
      editCustomerInfos: this.editCustomerInfos,
      editCustomerAddress: this.editCustomerAddress,
      initializeCustomerContext: this.initializeCustomerContext,
      deleteCustomerCard: this.deleteCustomerCard,
      deleteCustomerPaymentMethod: this.deleteCustomerPaymentMethod,
      deleteCustomerAddress: this.deleteCustomerAddress,
      setCustomerPaymentMethods: this.setCustomerPaymentMethods,
      addCustomerFeedbackToOrder: this.addCustomerFeedbackToOrder,
      fetchCustomer: this.fetchCustomer,
      setCustomerDiscountVouchers: this.setCustomerDiscountVouchers,
      setIsShadowAccount: this.setIsShadowAccount,
      setCustomerFromSessionStorage: this.setCustomerFromSessionStorage,
    };
    return (
      <CustomerContext.Provider value={customerContext}>
        {children}
      </CustomerContext.Provider>
    );
  }
}

CustomerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomerContext;

export { CustomerProvider };
