module.exports = {
  MyAccount: {
    url: '/mon-compte/',
    component: 'src/pagesComponents/myAccount/MyAccount.js',
    seo: {
      title: 'Mon compte - Tilli',
      description: 'Le récapitulatif de mes informations',
    },
    isRouteNotIndexedBySearchEngine: true,
  },
  MyInfos: {
    url: '/mon-compte/mes-informations/',
    component: 'src/pagesComponents/myAccount/MyInfos.js',
    seo: {
      title: 'Mon informations - Tilli',
      description: 'Mes informations',
    },
  },
  MyAddressesAndPayments: {
    url: '/mon-compte/mes-adresses-et-paiements/',
    component: 'src/pagesComponents/myAccount/MyAddressesAndPayments.js',
    seo: {
      title: 'Mes adresses et paiements - Tilli',
      description: 'Mes adresses et paiements',
    },
    isRouteNotIndexedBySearchEngine: true,
  },
  MyOrders: {
    url: '/mon-compte/mes-commandes/',
    component: 'src/pagesComponents/myAccount/MyOrders.js',
    seo: {
      title: 'Mes commandes - Tilli',
      description: 'Mes commandes',
    },
  },
  MyOrderDetails: {
    url: '/mon-compte/details-de-la-commande',
    component: 'src/pagesComponents/myAccount/MyOrderDetails.js',
    seo: {
      title: "Détail d'une commande - Tilli",
      description: "Détail d'une commande",
    },
    isRouteNotIndexedBySearchEngine: true,
  },
  MyMessages: {
    url: '/mon-compte/mes-messages/',
    component: 'src/pagesComponents/myAccount/MyMessages.tsx',
    seo: {
      title: 'Mes messages - Tilli',
      description: 'Mes messages',
      isRouteNotIndexedBySearchEngine: true,
    },
  },
};
