import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const defaultState = {
  stripe: {
    baseUrl: '',
    intentId: '',
    options: {},
  },
  initialize() {},
  setStripe() {},
};

const PaymentSystemContext = React.createContext(defaultState);

const expiryKey = 'paymentSystemContext_expiry';

const getFromLocalStorage = (key) => {
  const expiry = moment(localStorage.getItem(expiryKey));
  if (moment().add(-2, 'day').isBefore(expiry)) {
    return JSON.parse(localStorage.getItem(`paymentSystemContext_${key}`));
  }

  localStorage.removeItem(expiryKey);
  localStorage.removeItem(`paymentSystemContext_${key}`);

  return undefined;
};

const setInLocalStorage = (key, value) => {
  localStorage.setItem(expiryKey, (new Date()).toISOString());
  localStorage.setItem(`paymentSystemContext_${key}`, JSON.stringify(value));
};

const removeFromLocalStorage = (key) => localStorage.removeItem(`paymentSystemContext_${key}`);

const getInitialState = () => defaultState;

class PaymentSystemProvider extends React.Component {
  constructor() {
    super();
    this.state = getInitialState();
  }

  componentDidMount() {
    // Following OrderContext.componentDidMount behavior
    if (localStorage.getItem('landingPage') === 'Advice') {
      this.initialize();
    } else {
      const { stripe } = this.getStateFromLocalStorage();
      this.setStripe(stripe);
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    const { stripe } = this.state;

    if (stripe !== prevState.stripe) setInLocalStorage('stripe', stripe);
  }

  setStripe = (stripe) => {
    this.setState({ stripe });
  };

  getStateFromLocalStorage = () => {
    const { stripe: stripeState } = this.state;

    const stripe = getFromLocalStorage('stripe') || stripeState;

    return { stripe };
  };

  initialize = () => {
    this.setState(getInitialState());
    Object.keys(this.state).forEach((stateName) => {
      removeFromLocalStorage(stateName);
    });
  };

  render() {
    const { children } = this.props;
    const { stripe } = this.state;

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const paymentSystemContext = {
      stripe,
      initialize: this.initialize,
      setStripe: this.setStripe,
    };

    return (
      <PaymentSystemContext.Provider value={paymentSystemContext}>
        {children}
      </PaymentSystemContext.Provider>
    );
  }
}

PaymentSystemProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PaymentSystemContext;

export { PaymentSystemProvider };
