import { getDeliveryFees as getDeliveryFeesCore } from 'tilli-core/dist/services/zipcode';
import zones from 'tilli-core/dist/services/zipcodeData';
import { defaultCountry, Country } from '../config/countries';

const ParisZipcodePrefix: string = '75';
// cSpell: disable
const otherZipcodes: { [key: string]: string } = {
  '06000': 'Nice',
  '06150': 'Cannes',
  13001: 'Marseille',
  13002: 'Marseille',
  13004: 'Marseille',
  13005: 'Marseille',
  13006: 'Marseille',
  13007: 'Marseille',
  13008: 'Marseille',
  13009: 'Marseille',
  13010: 'Marseille',
  13011: 'Marseille',
  13012: 'Marseille',
  13013: 'Marseille',
  31000: 'Toulouse',
  33000: 'Bordeaux',
  33100: 'Bordeaux',
  33200: 'Bordeaux',
  33300: 'Bordeaux',
  33800: 'Bordeaux',
  35000: 'Rennes',
  44000: 'Nantes',
  59000: 'Lille',
  67000: 'Strasbourg',
  69001: 'Lyon',
  69002: 'Lyon',
  69003: 'Lyon',
  69004: 'Lyon',
  69005: 'Lyon',
  69006: 'Lyon',
  69007: 'Lyon',
  69008: 'Lyon',
  69009: 'Lyon',
  69100: 'Villeurbanne',
  69110: 'St-Foy-lès-Lyon',
  92000: 'Nanterre',
  92100: 'Boulogne-Billancourt',
  92110: 'Clichy',
  92120: 'Montrouge',
  92130: 'Issy-Les-Moulineaux',
  92140: 'Clamart',
  92150: 'Suresnes',
  92170: 'Vanves',
  92190: 'Meudon',
  92200: 'Neuilly',
  92210: 'Saint Cloud',
  92300: 'Levallois-Perret',
  92320: 'Chatillon',
  92360: 'Meudon',
  92380: 'Garches',
  92410: 'Ville d’Avray',
  92420: 'Vaucresson',
  92430: 'Marnes la Coquette',
  92500: 'Rueil-malmaison',
  92600: 'Asnières',
  92800: 'Puteaux',
  93100: 'Montreuil',
  93260: 'Les lilas',
  93310: 'Le Pré-Saint-Gervais',
  94160: 'Saint Mandé',
  94200: 'Ivry-sur-Seine',
  94205: 'Ivry-sur-Seine',
  94220: 'Charenton le pont',
  94300: 'Vincennes',
};

// cSpell: enable

type SubZone = {
  zipcodes: {
    [key: string]: string;
  };
};

type Zone = {
  subZones: SubZone[];
};

const validZipCode: string[] = zones.flatMap((z: Zone) => z.subZones).flatMap((subZone: SubZone) => Object.keys(subZone.zipcodes));
export const isZipcodeCover = (zipcode: string, country: Country = defaultCountry): boolean => country?.id === 'FR' && validZipCode.includes(zipcode);

export default function checkError(zipcode: string, country: Country = defaultCountry): boolean {
  return !country.zipcodeChecker(zipcode);
}

export function checkZipcodeErrorType(zipcode: string, country: Country): number {
  if (country.zipcodeChecker(zipcode)) {
    if (country.id === 'FR' && !isZipcodeCover(zipcode, country)) {
      return 3;
    }
    return 0;
  } return 2;
}

export function getLocality(zipcode: string): string | undefined {
  if (zipcode.startsWith(ParisZipcodePrefix)) {
    return 'Paris';
  }
  return otherZipcodes[zipcode];
}

export function getDeliveryFees(zipcode: string, country: Country): number {
  if (typeof country?.id === 'string') return getDeliveryFeesCore(zipcode, country.id);
  return getDeliveryFeesCore(zipcode, country);
}
