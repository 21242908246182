export function trackScreen(screen = '') {
  const normalizedScreenName = screen.endsWith('/') ? screen : `${screen}/`;
  if (normalizedScreenName.includes('mot-de-passe')) return;
  if (typeof fbq === 'function') {
    fbq('track', 'PageView'); // eslint-disable-line no-undef
    fbq('track', 'ViewContent', { content_name: normalizedScreenName }); // eslint-disable-line no-undef
  }
}

export function trackUser({ firstname, lastname, email }) {
  const fullname = `${firstname} ${lastname}`;
  if (typeof FS !== 'undefined') { // FS not defined on localhost and on staging
    FS.setUserVars({ displayName: fullname, email });
  }
}

export function trackUserEmail({ email }) {
  if (typeof FS !== 'undefined') { // FS not defined on localhost and on staging
    FS.setUserVars({ email });
  }
}
