import fetch from 'isomorphic-fetch';
import { API_URL } from '../config/config';
import { language } from '../../languages';

function getOriginHeader() {
  if (process.env.GATSBY_BRAND) {
    return `platform-${process.env.GATSBY_BRAND}`;
  }
  return 'site';
}

export const removeToken = () => {
  localStorage.removeItem('token');
};

export const getJwtToken = () => (
  typeof localStorage !== 'undefined' ? localStorage.getItem('token') : undefined
);

// eslint-disable-next-line default-param-last
const callApi = (endpoint, method = 'get', body, customHeaders = {}, isNotJSON = false) => {
  const jwtToken = getJwtToken();
  if (!endpoint.startsWith('/')) endpoint = `/${endpoint}`;
  return fetch(`${API_URL}${endpoint}`, {
    headers: {
      ...(isNotJSON ? {} : { 'Content-Type': 'application/json' }),
      'Origin-tilli': getOriginHeader(),
      'Language-tilli': language,
      ...(jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}),
      ...customHeaders,
    },
    method,
    body: isNotJSON ? body : JSON.stringify(body),
  })
    .then((response) => {
      if (response.status === 204) {
        return { json: {}, response };
      }
      return response.json()
        .then((json) => ({ json, response }))
        .catch(() => ({ json: {}, response }));
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }

      if (json.token && typeof localStorage !== 'undefined') {
        localStorage.setItem('token', json.token);
      }

      return json;
    });
};

export default callApi;
